<template>
    <v-text-field ref="myInput" type="number" :value="value" @input="handleInput()" :counter="max" :label="label"
        :name="name" :id="name" :rules="formRules" :readonly="isReadonly" :class="(isReadonly) ? 'isReadonly':''"
        :hideDetails="(isReadonly) ? true:false" :clearable="(isReadonly) ? false:true" outlined rounded dense>
    </v-text-field>
</template>
<script>
    export default {
        data() {
            return {
                formRules: [],
                content: this.value,
            };
        },
        methods: {
            setFormRules() {
                let validate = this.validation;
                let tmp_validate = [];
                validate.forEach(rule => {
                    switch (rule) {
                        case 'required':
                            tmp_validate.push(v => !!v || 'Tidak Boleh Kosong');
                            break;
                    }
                });


                if (this.max) {
                    tmp_validate.push(v => !!v && v.length <= this.max || "Maksimal " + this.max + " Karakter");
                }
                if (this.min) {
                    tmp_validate.push(v => !!v && v.length >= this.min || "Minimal " + this.min + " Karakter");
                }

                this.formRules = tmp_validate;
            },
            handleInput(e) {
                this.$emit('input', this.$refs.myInput.value)
            }
        },
        mounted() {
            this.setFormRules();
        },
        props: ['label', 'name', 'value', 'validation', 'max', 'min', 'isReadonly'],
        name: 'formInput',
    }
</script>